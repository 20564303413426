export enum AccountRatioStatus {
  ACTIVE = 'ACTIVE',
  DERISK = 'DERISK',
  LIQUIDATION = 'LIQUIDATION',
}

const LIQUIDATION_RATIO = 1.1;
const DERISK_RATIO = 1.5;

export const ACCOUNT_RATIO_STATUS_THRESHOLD_LABELS = {
  [AccountRatioStatus.ACTIVE]: `> ${DERISK_RATIO}`,
  [AccountRatioStatus.DERISK]: `<= ${DERISK_RATIO}`,
  [AccountRatioStatus.LIQUIDATION]: `${LIQUIDATION_RATIO}`,
};

export const getAccountRatioStatus = (accountRatio: number): AccountRatioStatus => {
  if (accountRatio <= LIQUIDATION_RATIO) return AccountRatioStatus.LIQUIDATION;
  if (accountRatio <= DERISK_RATIO) return AccountRatioStatus.DERISK;

  return AccountRatioStatus.ACTIVE;
};
