import { reactive } from 'vue';

import { withCache, type WithCacheState } from '@exchange/helpers/with-cache';
import type { BEMarketModel } from '@exchange/libs/market/service/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

type MarketInstrumentsServiceState = WithCacheState<Array<BEMarketModel>>;

const CACHE_TTL_MS = 1000 * 60 * 60; // 1 hour

const getDefaultState = (): MarketInstrumentsServiceState => ({
  cache: null,
  cacheTimestamp: null,
});

class MarketInstrumentsService {
  private readonly state = reactive<MarketInstrumentsServiceState>(getDefaultState());

  public get = withCache(
    async (): Promise<Array<BEMarketModel>> => {
      try {
        const data = await PublicRest.Instruments.get();

        return data;
      } catch (error) {
        logger.error('Fetching market instruments failed; retrying later', error);
        await retryService.waitForNextRetryTick();
        return await this.get();
      }
    },
    this.state,
    { ttlMs: CACHE_TTL_MS },
  );
}

const marketInstrumentsService = new MarketInstrumentsService();

export default marketInstrumentsService;
