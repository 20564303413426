<script lang="ts">
export default {
  name: 'FuturesAppropriatenessManualReviewModal',
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { type OtButtonVariant } from '@1trading/design-system';
import differenceInDays from 'date-fns/differenceInDays';
import { type PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { FuturesAppropriatenessResult, FuturesAppropriatenessResultStatus } from '@exchange/libs/rest-api/web-api/customer/futures-appropriateness-resource';

type Action = {
  action: string;
  actionFn: () => void;
  actionTestId: string;
  actionVariant?: OtButtonVariant;
};

const STATUS_ICON_MAP = {
  [FuturesAppropriatenessResultStatus.MANUAL_REVIEW]: 'info-circle-bulk',
  [FuturesAppropriatenessResultStatus.PASSED]: 'tick-circle-bulk',
  [FuturesAppropriatenessResultStatus.FAILED]: 'close-circle-bulk',
} satisfies Record<FuturesAppropriatenessResultStatus, string>;

const props = defineProps({
  result: { type: Object as PropType<FuturesAppropriatenessResult>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'close'): void;
  (e: 'deposit'): void;
}>();

const { t, te } = useI18n({ useScope: 'global' });

const statusTranslationKey = computed(() => `status-${props.result.status}${'canRetake' in props.result ? `.canRetake-${props.result.canRetake}` : ''}`);

const action = computed((): Action => {
  const action = t(`ui.futuresAppropriatenessManualReviewModal.${statusTranslationKey.value}.button`);

  switch (props.result.status) {
    case FuturesAppropriatenessResultStatus.PASSED:
      return {
        action,
        actionFn: () => emit('deposit'),
        actionTestId: 'deposit',
        actionVariant: 'success',
      };
    case FuturesAppropriatenessResultStatus.FAILED:
      return {
        action,
        actionFn: () => emit('close'),
        actionTestId: 'close',
      };
    case FuturesAppropriatenessResultStatus.MANUAL_REVIEW:
    default:
      return {
        action,
        actionFn: () => emit('back'),
        actionTestId: 'back',
      };
  }
});
</script>

<template>
  <x-modal
    class="futures-appropriateness-manual-review-modal"
    :title="t('ui.futuresAppropriatenessManualReviewModal.title')"
    :variant="variant"
    can-close
    wide
  >
    <template #content>
      <ot-icon
        :name="STATUS_ICON_MAP[props.result.status]"
        class="futures-appropriateness-manual-review-modal__icon"
        :class="{ 'futures-appropriateness-manual-review-modal__icon--error': props.result.status === FuturesAppropriatenessResultStatus.FAILED }"
      />
      <i18n-t
        :keypath="`ui.futuresAppropriatenessManualReviewModal.${statusTranslationKey}.message.text`"
        tag="p"
        scope="global"
      >
        <template
          v-if="'canRetakeFromDate' in props.result"
          #number
        >
          {{ differenceInDays(new Date(props.result.canRetakeFromDate), new Date()) }}
        </template>
        <template
          v-if="te(`ui.futuresAppropriatenessManualReviewModal.${statusTranslationKey}.message.linkText`)"
          #linkText
        >
          <x-link-external :href="$t(`ui.futuresAppropriatenessManualReviewModal.${statusTranslationKey}.message.linkHref`)">
            {{ $t(`ui.futuresAppropriatenessManualReviewModal.${statusTranslationKey}.message.linkText`) }}
          </x-link-external>
        </template>
      </i18n-t>
    </template>
    <template #buttons>
      <x-button
        :text="action.action"
        :variant="action.actionVariant ?? 'primary'"
        @click="action.actionFn"
      />
    </template>
  </x-modal>
</template>

<style lang="scss" scoped>
.futures-appropriateness-manual-review-modal {
  &__icon {
    position: relative;
    display: flex;
    width: 107px;
    height: 107px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    color: rgb(var(--v-theme-primary));

    &--error {
      color: rgb(var(--v-theme-error));
    }

    &::before {
      position: absolute;
      border-radius: 50%;
      background-color: rgb(var(--v-theme-primary));
      content: '';
      inset: 0;
      opacity: 0.3;
    }

    &--error::before {
      background-color: rgb(var(--v-theme-error));
    }

    :deep(svg) {
      width: 80%;
    }
  }
}
</style>
