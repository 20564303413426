import rest from '@exchange/libs/utils/rest/src';

const URL = '/tf/simple/vasps';

export interface VASP {
  did: string;
  name: string;
}

interface Pagination {
  page: number;
  per_page: number;
  total: number;
  order: string;
}

export interface NotabeneVASPListResponse {
  vasps: VASP[];
  pagination: Pagination;
}

export default function NotabeneVASPListResource({ base }) {
  return {
    async search(query?: string): Promise<NotabeneVASPListResponse> {
      const searchParams = new URLSearchParams();
      searchParams.append('fields', 'did, name');
      searchParams.append('per_page', '100');

      if (query) searchParams.set('q', query);

      return rest.unAuthorizedRequest({
        url: `${URL}?${searchParams.toString()}`,
        base,
      });
    },
  };
}
