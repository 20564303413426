import rest from '@exchange/libs/utils/rest/src';

const URL = '/payments';

export interface PaymentsValidatePayload {
  transactionAsset: string;
  transactionAmount: string;
  destination: string | number;
}

export type PaymentsValidateType = 'BELOW_THRESHOLD' | 'TRAVELRULE' | 'NON_CUSTODIAL';
export type PaymentsValidateBeneficiaryAddressType = 'UNKNOWN' | 'HOSTED' | 'UNHOSTED';
export type PaymentsValidateAddressSource = 'ADDRESS_GRAPH' | string;

export interface PaymentsValidateResponse {
  isValid: boolean;
  type: PaymentsValidateType;
  beneficiaryAddressType: PaymentsValidateBeneficiaryAddressType;
  addressSource: PaymentsValidateAddressSource;
  beneficiaryVASPdid?: string;
  beneficiaryVASPname?: string;
  errors?: string[];
  warnings?: string[];
}

export default function PaymentsResource({ base }) {
  return {
    async validate(payload: PaymentsValidatePayload): Promise<PaymentsValidateResponse> {
      return rest.unAuthorizedRequest({
        method: 'post',
        payload,
        url: `${URL}/validate`,
        base,
      });
    },
  };
}
