import { computed, reactive } from 'vue';

import type { NormalizedPerpsPosition, WSMarginUpdate } from '@exchange/libs/account/service/src/lib/wss-account-messages';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { getAccountRatioStatus, type AccountRatioStatus } from './account-ratio';

const getDefaultState = () => ({
  status: null,
});

type AccountRatioState = {
  status: AccountRatioStatus | null;
};

class AccountRatioService {
  private readonly logPrefix = 'AccountRatioService:';

  private readonly state = reactive<AccountRatioState>(getDefaultState());

  public status = computed(() => this.state.status);

  public processMarginUpdate(event: WSMarginUpdate) {
    const { status } = event;

    logger.log(`${this.logPrefix} processing margin update:`, JSON.stringify(event, null, 2));

    this.state.status = status;
  }

  public processPositionsUpdate(futuresPositions: NormalizedPerpsPosition[]) {
    const accountRatio = futuresPositions[0]?.account_ratio;

    if (typeof accountRatio === 'undefined') {
      return;
    }

    const status = getAccountRatioStatus(Number(accountRatio));

    if (status !== this.state.status) {
      logger.log(`${this.logPrefix} updating status: ${status}`);

      this.state.status = status;
    }
  }

  public reset() {
    Object.assign(this.state, getDefaultState());
  }
}

const accountRatioService = new AccountRatioService();

export default accountRatioService;
