import { computed, reactive, ref } from 'vue';

import { resetCache, withCache, type WithCacheState } from '@exchange/helpers/with-cache';
import WebRest from '@exchange/libs/rest-api/web-api';
import type { FuturesAppropriatenessAssessment } from '@exchange/libs/rest-api/web-api/customer/futures-appropriateness-resource';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

type FuturesServiceState = {
  assessment: FuturesAppropriatenessAssessment | null | undefined;
  leverage: WithCacheState<number | null>;
};

const getDefaultState = (): FuturesServiceState => ({
  assessment: null,
  leverage: {
    cache: null,
    cacheTimestamp: null,
  },
});

const LEVERAGE_CACHE_TTL_MS = 1000 * 60 * 5; // 5 minutes

class FuturesService {
  private readonly state = reactive<FuturesServiceState>(getDefaultState());

  public assessment = computed(() => this.state.assessment);

  public result = computed(() => this.state.assessment?.result);

  public leverage = computed(() => this.state.leverage.cache ?? undefined);

  public isFetchedAssessment = computed(() => this.state.assessment !== getDefaultState().assessment);

  public isLoadingAssessment = ref(false);

  public getFuturesAppropriatenessAssessment = async (refetch = false): Promise<FuturesAppropriatenessAssessment | undefined> => {
    if (this.state.assessment && !refetch) {
      return this.state.assessment;
    }

    if (this.isLoadingAssessment.value) {
      return undefined;
    }

    this.isLoadingAssessment.value = true;

    try {
      const data = await WebRest.Customer.FuturesAppropriateness.get();

      this.state.assessment = data;

      return data;
    } catch (error) {
      logger.error('Fetching futures appropriateness assessment failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return await this.getFuturesAppropriatenessAssessment();
    } finally {
      this.isLoadingAssessment.value = false;
    }
  };

  public refetchFuturesAppropriatenessAssessment = async () => this.getFuturesAppropriatenessAssessment(true);

  public servicesAgreement = {
    get: async (): Promise<{ date: string } | undefined> => {
      try {
        const data = await WebRest.Customer.Agreement.getServicesAgreement();

        return data;
      } catch (error) {
        logger.error('Fetching services agreement failed; retrying later', error);
        await retryService.waitForNextRetryTick();
        return this.servicesAgreement.get();
      }
    },
    set: () => WebRest.Customer.Agreement.setServicesAgreement(),
  };

  private isLoadingLeverage = ref(false);

  public getLeverage = withCache(
    async (): Promise<number | null> => {
      if (this.isLoadingLeverage.value) {
        return null;
      }

      this.isLoadingLeverage.value = true;

      try {
        return await WebRest.Account.Futures.getLeverage();
      } catch (error) {
        logger.error('Fetching futures leverage failed; retrying later', error);
        await retryService.waitForNextRetryTick();
        return await this.getLeverage();
      } finally {
        this.isLoadingLeverage.value = false;
      }
    },
    this.state.leverage,
    {
      ttlMs: LEVERAGE_CACHE_TTL_MS,
    },
  );

  public reset = () => Object.assign(this.state, getDefaultState());

  public resetLeverage = () => resetCache(this.state.leverage, getDefaultState().leverage);
}

const futuresService = new FuturesService();

export default futuresService;
