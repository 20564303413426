import rest from '@exchange/libs/utils/rest/src';

const URL = '/futures-appropriateness-assessment';

export const futuresAppropriatenessAssessmentKeys = [
  'assetFamiliarity',
  'investmentServiceAwareness',
  'derivativeTransactionCount',
  'averageTransactionValue',
  'educationLevelAttainment',
  'financialInstrumentExposure',
  'riskAppetiteSelection',
  'futuresTradingRiskAppetite',
  'maximumDerivativesLoss',
  'marginRequirementCalculation',
  'investmentGoal',
  'investmentTimeHorizon',
  'transactionAdviceNeed',
] as const;
export type FuturesAppropriatenessAssessmentKey = (typeof futuresAppropriatenessAssessmentKeys)[number];
export const futuresAppropriatenessAssessmentAllowedAnswers = {
  assetFamiliarity: ['CRYPTO', 'EQUITIES', 'COMMODITIES', 'FIXED_INCOME', 'STRUCTURED_PRODUCTS', 'DEBT_SECURITIES', 'PRIVATE_EQUITY', 'NONE'] as const,
  investmentServiceAwareness: [
    'ORDER_HANDLING',
    'ORDER_EXECUTION',
    'PROPRIETARY_TRADING',
    'PORTFOLIO_MANAGEMENT',
    'INVESTMENT_ADVISORY',
    'UNDERWRITING',
    'PLACING',
    'MTF_OPERATION',
    'OTF_OPERATION',
    'NONE',
  ] as const,
  derivativeTransactionCount: ['UP_TO_4', 'FROM_5_TO_9', 'FROM_10_TO_50', 'MORE_THAN_50', 'NONE'] as const,
  averageTransactionValue: ['UP_TO_99', 'FROM_10_TO_199', 'FROM_200_TO_499', 'FROM_500_TO_999', 'FROM_1000_TO_2999', 'MORE_THAN_3000', 'NONE'] as const,
  educationLevelAttainment: ['PHD', 'COLLAGE_UNI', 'H_SCHOOL', 'S_SCHOOL', 'P_SCHOOL'] as const,
  financialInstrumentExposure: ['INVEST_PROFESSIONAL', 'FIN_SERVICES', 'ACCOUNTING', 'NONE'] as const,
  riskAppetiteSelection: ['CAPITAL_PRESERVATION', 'MODERATE_RISK_TOLERANCE', 'MODERATE-TO-HIGH_RISK_TOLERANCE', 'AGGRESSIVE_GROWTH', 'HIGH_RISK_ACCEPTANCE'] as const,
  futuresTradingRiskAppetite: ['1', '2', '3', '4', '5'] as const,
  maximumDerivativesLoss: ['LEVERAGED_INVESTING', 'PARTIAL_COLLATERAL', 'CAPITAL_PRESERVATION', 'UNCERTAIN'] as const,
  marginRequirementCalculation: ['500', '1000', '2000', 'UNCERTAIN'] as const,
  investmentGoal: ['RETIREMENT_PLANNING', 'FINANCIAL_STABILITY', 'FUTURE_SAVINGS', 'SPECULATION', 'INCOME_GENERATION'] as const,
  investmentTimeHorizon: ['SHORT-TERM_GOALS', 'MEDIUM-TERM_GOALS', 'MEDIUM-TERM_INVESTMENTS', 'LONG-TERM_INVESTMENTS'] as const,
  transactionAdviceNeed: ['YES', 'NO'] as const,
};

type FuturesAppropriatenessAssessmentAnswer = {
  [K in FuturesAppropriatenessAssessmentKey]: {
    key: K;
    answer: Array<(typeof futuresAppropriatenessAssessmentAllowedAnswers)[K][number]>;
  };
}[FuturesAppropriatenessAssessmentKey];

export type FuturesAppropriatenessAssessmentAnswers = Array<FuturesAppropriatenessAssessmentAnswer>;

export enum FuturesAppropriatenessResultStatus {
  PASSED = 0,
  MANUAL_REVIEW = 1,
  FAILED = 2,
}

type FuturesAppropriatenessResultPassed = {
  status: FuturesAppropriatenessResultStatus.PASSED;
  manualReview?: true;
};

type FuturesAppropriatenessResultManualReview = {
  status: FuturesAppropriatenessResultStatus.MANUAL_REVIEW;
};

type FuturesAppropriatenessResultFailedRetakeEligibilityTrue = {
  canRetake: true;
  hadRetakeCooldown?: true;
};

type FuturesAppropriatenessResultFailedRetakeEligibilityFalse = {
  canRetake: false;
  canRetakeFromDate: string;
};

type FuturesAppropriatenessResultFailed = {
  status: FuturesAppropriatenessResultStatus.FAILED;
  manualReview?: true;
} & (FuturesAppropriatenessResultFailedRetakeEligibilityTrue | FuturesAppropriatenessResultFailedRetakeEligibilityFalse);

export type FuturesAppropriatenessResult = FuturesAppropriatenessResultPassed | FuturesAppropriatenessResultManualReview | FuturesAppropriatenessResultFailed;

export type FuturesAppropriatenessAssessment = {
  answers: FuturesAppropriatenessAssessmentAnswers;
  date: string;
  result?: FuturesAppropriatenessResult;
};

export default function Resource({ base }) {
  return {
    get: async (): Promise<FuturesAppropriatenessAssessment | undefined> => {
      try {
        return await rest.request(
          {
            url: URL,
            base,
          },
          {
            useSubaccount: false,
          },
        );
      } catch (e) {
        if ((e as { status: number }).status === 404) {
          return undefined;
        }

        throw e;
      }
    },
  };
}
