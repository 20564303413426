import type { BEPublicFeeGroup } from '@exchange/libs/fees/service/src/lib/fee-tiers-model';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/fees';

export default function FeesResource({ base }) {
  return {
    get(): Promise<BEPublicFeeGroup[]> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
