import type { BEAccountFees } from '@exchange/libs/fees/service/src/lib/fee-tiers-model';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/fees';

export default function FeesResource({ base, accountUrl }) {
  return {
    get(): Promise<BEAccountFees> {
      return rest.request({
        url: `${accountUrl}${URL}`,
        base,
      });
    },
  };
}
