import rest from '@exchange/libs/utils/rest/src';

const URL = '/market-type-trading-eligibility';

export type MarketTypeTradingEligibility = {
  spot: boolean;
  futures: boolean;
};

export default function MarketTypeTradingEligibilityResource({ base, userUrl }) {
  const marketTypeTradingEligibilityUrl = `${userUrl}${URL}`;

  return {
    async get(): Promise<MarketTypeTradingEligibility> {
      const data = await rest.request(
        {
          url: marketTypeTradingEligibilityUrl,
          base,
        },
        {
          useSubaccount: false,
        },
      );

      return data;
    },
  };
}
