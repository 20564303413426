<script lang="ts">
export default {
  name: 'FuturesAppropriatenessRetakeModal',
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { type PropType } from 'vue';

import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

defineProps({
  canDismiss: { type: Boolean, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'dismiss'): void;
  (e: 'retake'): void;
}>();
</script>

<template>
  <x-modal
    class="futures-appropriateness-retake-modal"
    :title="$t('ui.futuresAppropriatenessRetakeModal.title')"
    :variant="variant"
    can-close
    wide
  >
    <template #content>
      <ot-icon
        name="info-circle-bulk"
        class="futures-appropriateness-retake-modal__icon"
      />
      <div class="futures-appropriateness-retake-modal__message">
        {{ $t('ui.futuresAppropriatenessRetakeModal.message') }}
      </div>
    </template>
    <template #buttons>
      <x-button
        variant="primary"
        :text="$t('ui.futuresAppropriatenessRetakeModal.action.retakeTest')"
        @click="emit('retake')"
      />
      <x-button
        v-if="canDismiss"
        variant="outline"
        :text="$t('ui.futuresAppropriatenessRetakeModal.action.dismiss')"
        @click="emit('dismiss')"
      />
      <x-button
        v-else
        variant="outline"
        :text="$t('fundamentals.goBack')"
        @click="emit('back')"
      />
    </template>
  </x-modal>
</template>

<style lang="scss" scoped>
.futures-appropriateness-retake-modal {
  &__icon {
    position: relative;
    display: flex;
    width: 107px;
    height: 107px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    color: rgb(var(--v-theme-primary));

    &::before {
      position: absolute;
      border-radius: 50%;
      background-color: rgb(var(--v-theme-primary));
      content: '';
      inset: 0;
      opacity: 0.3;
    }

    :deep(svg) {
      width: 80%;
    }
  }

  &__message {
    white-space: pre-wrap;
  }
}
</style>
