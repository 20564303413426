import debounce from 'lodash/debounce';
import { isEmpty, difference as arrayDiff, negate, orderBy } from 'lodash/fp';
import { computed, reactive, watch } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { getFirstItemsInChunks } from '@exchange/helpers/get-first-items-in-chunks';
import { forEach } from '@exchange/helpers/lodash-fp-no-cap';
import difference, { noDifference } from '@exchange/helpers/object-diff';
import percentFormatting from '@exchange/helpers/percent-formatting';
import { persistValue, syncWithStorage } from '@exchange/helpers/persistance-helper';
import { AggregationOperation } from '@exchange/libs/orderbook/service/src';
import orderbookService from '@exchange/libs/orderbook/service/src/lib/orderbook.service';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { WSIncomingEventTypes, WSIncomingEventTypesSpecial } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import { doesCurrencyHaveMarket } from './helpers';
import marketInstrumentsService from './market-instruments.service';
import {
  type MarketDataToCompare,
  MarketModel,
  MarketState,
  MarketType,
  type MarketUpdate,
  type WSSMarketTickerUpdate,
  type WSSMarketTickerUpdateMessage,
  type WSSMarketUpdateMessage,
} from './market-model';
import marketTickerChannel from './MarketTicker.channel';

export type MarketLinkInfo = { text: string; id: string };
export type CurrenciesToMarkets = Dictionary<{ primary?: Array<MarketLinkInfo>; secondary?: Array<MarketLinkInfo> }>;

interface MarketsState {
  markets: Record<string, MarketModel>;
  instrumentsFromFirstMarketTick: Array<string>;
  selectedMarketId: string;
}

class Markets {
  private readonly logPrefix = 'MarketService:';

  private getDefaultState = () => ({
    markets: {},
    instrumentsFromFirstMarketTick: [],
    selectedMarketId: syncWithStorage(CONSTANTS.SELECTED_MARKET, CONSTANTS.DEFAULT_MARKET_ID),
  });

  private marketTickerUpdates = new Map<string, WSSMarketTickerUpdate>();

  private readonly state = reactive<MarketsState>(this.getDefaultState());

  public markets = computed(() => this.state.markets);

  public instrumentsFromFirstMarketTick = computed(() => this.state.instrumentsFromFirstMarketTick);

  public selectedMarketId = computed(() => this.state.selectedMarketId);

  constructor() {
    (async () => {
      await this.awaitMarkets();

      /*
       * We can't reliably get the futures flag for both unauthorized and authorized users
       * without blocking the UI, so we re-initialize the markets when the flag changes.
       */
      watch(launchdarkly.flags['futures-enabled'], async () => this.fetchMarkets(), { immediate: true });
    })();
  }

  public getMarket = (marketId?: string) => {
    if (!marketId) {
      return undefined;
    }

    if (this.state.markets[marketId]) {
      return this.state.markets[marketId];
    }

    return undefined;
  };

  public getMarketName = (market: MarketModel | undefined) => market?.name;

  public getMarketState = (market: MarketModel | undefined) => market?.state;

  public getMarketLastPrice = (market: MarketModel | undefined) => market?.lastPrice ?? Number.NaN;

  public getMarketPrecision = (market: MarketModel | undefined) => ({
    amount: market?.amountPrecision ?? 0,
    market: market?.marketPrecision ?? 0,
    total: market?.totalPrecision ?? 0,
  });

  public marketDataLoading = computed(() => {
    if (isEmpty(this.state.instrumentsFromFirstMarketTick) || isEmpty(this.state.markets)) {
      return true;
    }

    return !this.state.instrumentsFromFirstMarketTick
      .reduce((acc: Array<number>, instrument: string) => {
        const market = this.state.markets[instrument];

        if (market) {
          acc.push(market.allDay.volume);
        }

        return acc;
      }, [])
      .every(negate(Number.isNaN));
  });

  private marketTickerChannelSubscription: (() => Promise<void>) | null = null;

  public doesMarketExistForCurrency = (currencyCode: string) => {
    const ids = Object.keys(this.markets.value);

    return doesCurrencyHaveMarket(ids)(currencyCode);
  };

  public awaitMarkets = (): Promise<Dictionary<MarketModel>> => {
    if (!isEmpty(this.state.markets)) {
      return Promise.resolve(this.state.markets);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.markets,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  public getMarketTicker = async (marketId: string) => PublicRest.MarketTicker.getInstrument(marketId);

  public getMarketsTicker = async () => PublicRest.MarketTicker.get();

  private marketsTickerPollingTimeoutID = 0;

  public marketsTickerPollingStop = () => {
    clearTimeout(this.marketsTickerPollingTimeoutID);
  };

  async subscribeToMarketTicker(markets: Dictionary<MarketModel>) {
    try {
      await this.marketTickerChannelSubscription?.();
      this.state.instrumentsFromFirstMarketTick = [];

      await new Promise<void>((res, rej) => {
        this.marketTickerChannelSubscription = marketTickerChannel.subscribe(
          {
            markets: Object.keys(markets),
            onMessage: (message) => {
              switch (message.type) {
                case WSIncomingEventTypes.MARKET_TICKER_UPDATES:
                  this.upsertMarketTicker((message as WSSMarketTickerUpdateMessage).ticker_updates);
                  break;
                case WSIncomingEventTypes.MARKET_UPDATES:
                  this.processMarketUpdate((message as WSSMarketUpdateMessage).update);
                  break;
                case WSIncomingEventTypesSpecial.UNSUBSCRIBED:
                  break;
                case WSIncomingEventTypes.ERROR:
                  logger.error(`${this.logPrefix} WSS ERROR`, message);
                  break;
                default:
                  logger.warn(`${this.logPrefix} Unknown message type`, message.type);
              }
            },
          },
          {
            success: () => res(),
            fail: (error) => rej(error),
          },
        );
      });
    } catch (error) {
      logger.warn(`${this.logPrefix} Subscribing failed; retrying later`, error);
      await this.marketTickerChannelSubscription?.();
      await retryService.waitForNextRetryTick();
      await this.subscribeToMarketTicker(markets);
    }
  }

  private setMarketsAndSubscribeToMarketTicker = async (markets: Record<string, MarketModel>) => {
    this.subscribeToMarketTicker(markets);

    /* first time */
    if (isEmpty(this.state.markets)) {
      this.state.markets = markets;
      return;
    }

    /* after nap */
    const newMarketsKeys = Object.keys(markets);
    const stateMarketKeys = Object.keys(this.state.markets);

    const newMarkets = newMarketsKeys.reduce((acc: Record<string, MarketModel>, marketName: string) => {
      const stateMarket = this.state.markets[marketName];
      const market = markets[marketName];

      if (!market) {
        return acc;
      }

      if (stateMarket) {
        const incomingMarketData = market.getDataToCompare();

        forEach((value, key) => {
          stateMarket[key] = value;
        }, incomingMarketData);
      } else {
        acc[marketName] = market;
      }

      return acc;
    }, {});

    if (!isEmpty(newMarkets)) {
      forEach((market: MarketModel, marketName: string) => {
        this.state.markets[marketName] = market;
      }, newMarkets);
    }

    const removedNames = arrayDiff(stateMarketKeys, newMarketsKeys);
    if (removedNames.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const name of removedNames) {
        delete this.state.markets[name];
      }
    }
  };

  public fetchMarkets = async () => {
    const marketInstruments = await marketInstrumentsService.get();
    const isFuturesEnabled = launchdarkly.flags['futures-enabled'].value;

    const markets: Record<string, MarketModel> = marketInstruments
      .filter((market) => {
        // Filter out closed markets
        if (market.state === MarketState.CLOSED) {
          return false;
        }

        // Filter out futures markets if feature flag is off
        if (market.type === MarketType.PERP && !isFuturesEnabled) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        const aBC = MarketModel.getMarketId(a.base.code, a.quote.code);
        const mBC = MarketModel.getMarketId(b.base.code, b.quote.code);

        if (aBC < mBC) {
          return -1;
        }

        if (aBC > mBC) {
          return 1;
        }

        return 0;
      })
      .reduce((acc, market) => {
        const mm = new MarketModel(market);

        acc[mm.id] = mm;
        return acc;
      }, {});

    await this.setMarketsAndSubscribeToMarketTicker(markets);
  };

  public getValidMarketId = async ({ marketId, isFutures }: { marketId: string; isFutures?: boolean }): Promise<string> => {
    let selectedMarketId = marketId;

    const marketStorageData = {
      key: CONSTANTS.SELECTED_MARKET,
      default: isFutures ? CONSTANTS.DEFAULT_MARKET_ID_FUTURES : CONSTANTS.DEFAULT_MARKET_ID,
    };

    if (isEmpty(this.state.markets)) {
      logger.warn(`${this.logPrefix} Markets have not been loaded yet; retrying later`);
      await retryService.waitForNextRetryTick();

      return this.getValidMarketId({ marketId: selectedMarketId, isFutures });
    }

    if (!this.state.markets[selectedMarketId]) {
      selectedMarketId = syncWithStorage(marketStorageData.key, marketStorageData.default);

      if (!this.state.markets[selectedMarketId]) {
        persistValue(marketStorageData.key)(marketStorageData.default);
        selectedMarketId = marketStorageData.default;
      }
    }

    if (!this.state.markets[selectedMarketId]) {
      logger.error(`${this.logPrefix} Markets do not include selected market ${selectedMarketId}`);
      selectedMarketId = Object.entries(this.state.markets).find(([_, market]) => market.type === (isFutures ? MarketType.PERP : MarketType.SPOT))?.[0] || '';

      return this.getValidMarketId({ marketId: selectedMarketId, isFutures });
    }

    persistValue(marketStorageData.key)(selectedMarketId);

    this.state.selectedMarketId = selectedMarketId;

    return selectedMarketId;
  };

  // TODO check if we should debounce it at all
  private updateMarketData = debounce(
    () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [instrument, update] of this.marketTickerUpdates) {
        const market = this.state.markets[instrument];

        if (market) {
          this.marketTickerUpdates.delete(instrument);

          market.lastPrice = Number(update.last_price);
          market.allDay.high = Number(update.high);
          market.allDay.low = Number(update.low);
          market.allDay.volume = Number(update.volume);
          market.allDay.priceChange = Number(update.price_change);
          market.allDay.priceChangePercentage = Number(update.price_change_percentage);
          market.allDay.pricePointData = update.price_points?.map(({ price }) => price) || [];

          const pricePointDataLength = market.allDay.pricePointData.length;

          if (pricePointDataLength) {
            market.allDay.pricePointData[pricePointDataLength - 1] = update.last_price;
          }
        }
      }
    },
    200,
    { maxWait: 500 },
  );

  public upsertMarketTicker = (tickerUpdates: Array<WSSMarketTickerUpdate>) => {
    if (!this.state.instrumentsFromFirstMarketTick.length) {
      this.state.instrumentsFromFirstMarketTick = tickerUpdates.map((u) => u.instrument);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const update of tickerUpdates) {
      this.marketTickerUpdates.set(update.instrument, update);
    }

    this.updateMarketData();
  };

  private processMarketUpdate = async (update: MarketUpdate) => {
    const marketId = MarketModel.getMarketId(update.base.code, update.quote.code);
    const market = new MarketModel(update);
    const stateMarket = this.state.markets[marketId];

    if (!stateMarket) {
      // new market
      if (market.state === MarketState.CLOSED) {
        return;
      }

      await this.setMarketsAndSubscribeToMarketTicker({
        ...this.state.markets,
        [market.id]: market,
      });

      return;
    }

    const stateMarketData = stateMarket.getDataToCompare();
    const incomingMarketData = market.getDataToCompare();
    const hasMarketChanged = !noDifference(stateMarketData, incomingMarketData);

    if (hasMarketChanged) {
      if (update.state === MarketState.CLOSED) {
        const { [marketId]: omit, ...markets } = this.state.markets;

        await this.setMarketsAndSubscribeToMarketTicker(markets);

        return;
      }

      const marketDiff = difference(incomingMarketData, stateMarketData) as MarketDataToCompare;

      forEach((value, key) => {
        stateMarket[key] = value;
      }, marketDiff);

      if (marketDiff.marketPrecision || marketDiff.amountPrecision) {
        (await orderbookService.getOrderbook({ id: marketId, precision: stateMarket.marketPrecision })).value.doAggregation(
          AggregationOperation.RESET,
          stateMarket.marketPrecision,
        );
      }
    }
  };

  public updateLastPrice = ({ lastPrice, marketId }: { lastPrice: string; marketId: string }) => {
    const market = this.state.markets[marketId];

    if (market) {
      market.lastPrice = Number(lastPrice);
    }
  };

  public getCurrenciesToMarkets = (markets: Dictionary<MarketModel>) =>
    Object.values(markets).reduce(
      (acc, market: MarketModel | undefined) => {
        if (!market) {
          return acc;
        }

        const quoteCode = market.quote.code;
        const baseCode = market.base.code;
        const data = {
          text: market.name,
          id: market.id,
        };

        const objQuoteCode = acc[quoteCode];
        const objBaseCode = acc[baseCode];

        if (objQuoteCode) {
          if (objQuoteCode.secondary) {
            objQuoteCode.secondary.push(data);
          } else {
            objQuoteCode.secondary = [data];
          }
        } else {
          acc[quoteCode] = { secondary: [data] };
        }

        if (objBaseCode) {
          if (objBaseCode.primary) {
            objBaseCode.primary.push(data);
          } else {
            objBaseCode.primary = [data];
          }
        } else {
          acc[baseCode] = { primary: [data] };
        }

        return acc;
      },
      <CurrenciesToMarkets>{},
    );

  public marketStats = {
    get: async () => {
      const defaultPricePrecision = 8;
      const getPriceChange = (firstPrice: string, lastPrice: string) => {
        const first = new BigNumber(firstPrice);
        const last = new BigNumber(lastPrice);
        const diff = last.minus(first);
        const avg = first.plus(last).div(2);

        return {
          percentage: diff.div(avg).times(100),
          isPositive: diff.gt(0),
          isNegative: diff.lt(0),
          isZero: diff.eq(0),
        };
      };
      const getPrecision = (price: string) => {
        if (price === 'NaN') {
          return 0;
        }

        const parts = price.split('.');

        return Math.max(parts[1]?.length ?? defaultPricePrecision, 0);
      };
      let info = await PublicRest.MarketStats.Views.get().catch(() => []);

      info = orderBy(['count', 'instrument'], ['desc'], info);
      const priceChange = await CryptoInfoRest.PriceChange.get(info.map((i) => i.instrument)).catch(() => []);

      return info.map((item) => {
        const [base, quote] = item.instrument.split('_');
        const name = [base, quote].join('/');

        const marketPriceChange = priceChange[item.instrument]?.length ? getFirstItemsInChunks(priceChange[item.instrument], 32) : ['NaN'];

        const first = marketPriceChange.at(0);
        const last = marketPriceChange.at(-1);
        const priceChangePercentageInfo = getPriceChange(first, last);
        const priceChangePercentageToDisplay = percentFormatting(priceChangePercentageInfo.percentage);
        const pricePrecision = getPrecision(last);

        return {
          name,
          base: base ?? '',
          count: item.count,
          lastPrice: last,
          pricePrecision,
          pricePoints: marketPriceChange,
          priceChange: priceChangePercentageInfo,
          priceChangePercentageToDisplay,
        };
      });
    },
    set: (instrument: string, price: string) => PublicRest.MarketStats.Views.set(instrument, price),
  };
}

const marketService = new Markets();

export default marketService;
