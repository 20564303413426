import { difference } from 'lodash/fp';
import { isMobileOnly } from 'mobile-device-detect';
import { reactive, ref, type Ref } from 'vue';

import { accountService } from '@exchange/libs/account/service/src';
import type {
  WSSTradingUpdate,
  OrderCreatedUpdate,
  OrderBookedUpdate,
  OrderRejectedUpdate,
  TradeSettledUpdate,
  OrderClosedUpdate,
  StopOrderTriggeredUpdate,
  OrderFullyFilledUpdate,
  ActivePositionsSnapshot,
  PerpsPosition,
  PerpsPositionUpdate,
  PerpsSettlement,
  NormalizedPerpsPosition,
} from '@exchange/libs/account/service/src/lib/wss-account-messages';
import { TradingUpdateType } from '@exchange/libs/account/service/src/lib/wss-account-messages';
import { authService } from '@exchange/libs/auth/service/src';
import { balanceService } from '@exchange/libs/balances/service/src';
import { accountRatioService } from '@exchange/libs/futures/service/src';
import { marketService } from '@exchange/libs/market/service/src';
import { MyOrdersListType, OrderStatus, OrderSnapshotType, OrderAnimation } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import type { BEOrderSnapshotModel, InMyOrderType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import { OrderWithTradesModel } from '@exchange/libs/order/shared-model/src/lib/order-model';
import type { OrderTradesPair } from '@exchange/libs/order/shared-model/src/lib/order-model';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { settingsService } from '@exchange/libs/settings/service/src';
import { toastManagerInstance, OrderCancelledToast, OrderCreatedToast, OrderFilledToast, OrderRejectedToast, StopOrderTriggeredToast } from '@exchange/libs/toasts/src';
import { TradeModel } from '@exchange/libs/trade/trade-history/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export const OrderHistoryFilterCategory = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export type TOrderHistoryFilterCategory = (typeof OrderHistoryFilterCategory)[keyof typeof OrderHistoryFilterCategory];
export interface OrderHistoryFilter {
  from?: string;
  to?: string;
  category: TOrderHistoryFilterCategory;
  withCancelledAndRejected: boolean;
  instrumentCode?: string;
  cursor?: string;
}

const orderToaster = {
  showOrderToast: ({ content, orderId, event }: { content: unknown; orderId: string; event: WSSTradingUpdate }) => {
    toastManagerInstance.addToast({
      order: orderId,
      content,
      props: {
        event,
      },
    });
  },

  showOrderRejectedToast: (orderId: string, event: WSSTradingUpdate) => {
    orderToaster.showOrderToast({
      orderId,
      event,
      content: OrderRejectedToast,
    });
  },

  showOrderCreatedToast(orderId: string, event: WSSTradingUpdate) {
    orderToaster.showOrderToast({
      orderId,
      event,
      content: OrderCreatedToast,
    });
  },

  showOrderClosedToast(orderId: string, event: WSSTradingUpdate) {
    orderToaster.showOrderToast({
      orderId,
      event,
      content: OrderCancelledToast,
    });
  },

  showOrderTriggeredToast(orderId: string, event: WSSTradingUpdate) {
    orderToaster.showOrderToast({
      orderId,
      event,
      content: StopOrderTriggeredToast,
    });
  },

  showOrderFilledToast(order?: OrderWithTradesModel) {
    if (order && order.status === OrderStatus.FILLED_FULLY) {
      const { markets } = marketService;

      toastManagerInstance.addToast({
        order: order.id,
        content: OrderFilledToast,
        props: {
          order,
          market: markets.value?.[order.instrumentCode],
        },
      });
    }
  },
};

export interface ReactiveOrdersData {
  orderId: string;
  counter: number;
  key: string;
  instrumentCode: string;
}
const keyDelimiter = '__';
const createKey = (orderId: string, count: number) => `${orderId}${keyDelimiter}${count}`;

interface OrdersMaps {
  [MyOrdersListType.open]: Map<string, OrderWithTradesModel>;
  [MyOrdersListType.filled]: Map<string, OrderWithTradesModel>;
}

interface ReactiveData {
  [MyOrdersListType.open]: Ref<Record<string, ReactiveOrdersData>>;
  [MyOrdersListType.filled]: Ref<Record<string, ReactiveOrdersData>>;
}

interface OrderLifecycleLog {
  sequence: number;
  time: string;
  type: string;
}

class OrderService {
  private readonly logPrefix = 'OrderService:';

  private maps: OrdersMaps = {
    [MyOrdersListType.open]: new Map(),
    [MyOrdersListType.filled]: new Map(),
  };

  public reactiveData: ReactiveData = {
    [MyOrdersListType.open]: ref({}),
    [MyOrdersListType.filled]: ref({}),
  };

  public reactivePerpsData: Ref<Record<string, NormalizedPerpsPosition>> = ref({});

  private cancellingOrders: Set<string> = new Set();

  private ordersLifecycleLog: Map<string, Array<OrderLifecycleLog>> = new Map();

  private updateCache: Map<string, Array<WSSTradingUpdate>> = new Map();

  public snapshotReceived = reactive({
    [MyOrdersListType.open]: false,
    [MyOrdersListType.filled]: false,
  });

  private orderUpdateQueue: Map<string, WSSTradingUpdate[]> = new Map();

  public getOrderById(orderId: string, type: MyOrdersListType) {
    return this.maps[type].get(orderId);
  }

  public updateReactiveDataKey = (orderId: string, myOrdersType: InMyOrderType) => {
    const updateData = (tb: MyOrdersListType) => {
      const rod = this.reactiveData[tb].value[orderId];

      if (!rod) {
        return;
      }

      const counter = rod.counter + 1;
      rod.counter = counter;
      rod.key = createKey(orderId, counter);
    };

    const processOneBeforeType = (typeB: MyOrdersListType, typeN: Array<MyOrdersListType> | undefined) => {
      if (!typeN) {
        this.updateMapDelete(typeB, orderId);
        return;
      }

      const order = this.getOrderById(orderId, typeB);

      if (typeN.length === 1) {
        const [tn] = typeN;

        if (tn === typeB) {
          updateData(typeB);
          return;
        }

        if (order && tn) {
          this.updateMapSet(tn, orderId, order);
        }

        this.updateMapDelete(typeB, orderId);
      }

      if (typeN.length === 2) {
        const [tn1, tn2] = typeN;

        if (tn1 === typeB || tn2 === typeB) {
          updateData(typeB);
        }

        if (tn1 !== typeB && order && tn1) {
          this.updateMapSet(tn1, orderId, order);
        }

        if (tn2 !== typeB && order && tn2) {
          this.updateMapSet(tn2, orderId, order);
        }
      }
    };

    const processTwoBeforeType = (typeB1: MyOrdersListType, typeB2: MyOrdersListType, typeN: Array<MyOrdersListType> | undefined) => {
      if (!typeN) {
        this.updateMapDelete(typeB1, orderId);
        this.updateMapDelete(typeB2, orderId);

        return;
      }

      const typeDiff = difference([typeB1, typeB2], typeN);

      if (typeDiff.length === 0) {
        updateData(typeB1);
        updateData(typeB2);

        return;
      }

      if (typeN.length === 1) {
        // @ts-ignore TODO fix types
        const [typeToRemove] = difference([typeB1, typeB2], typeN[0]) as Array<MyOrdersListType>;

        if (typeToRemove) {
          this.updateMapDelete(typeToRemove, orderId);
        }

        if (typeN[0]) {
          updateData(typeN[0]);
        }
      }
    };

    if (!myOrdersType.before) {
      logger.log(`An order with ${orderId} does not exist`);

      return;
    }

    if (myOrdersType.before.length === 1) {
      const [tb] = myOrdersType.before;

      if (tb) {
        processOneBeforeType(tb, myOrdersType.now);
      }
    }

    if (myOrdersType.before.length === 2) {
      const [tb1, tb2] = myOrdersType.before;

      if (tb1 && tb2) {
        processTwoBeforeType(tb1, tb2, myOrdersType.now);
      }
    }
  };

  private updateMapReset(type: MyOrdersListType) {
    this.maps[type].clear();
    this.reactiveData[type].value = {};
  }

  private updateMapSet(type: MyOrdersListType, orderId: string, value: OrderWithTradesModel) {
    this.maps[type].set(orderId, value);
    this.reactiveData[type].value[orderId] = {
      orderId,
      counter: 0,
      key: createKey(orderId, 0),
      instrumentCode: value.instrumentCode,
    };
  }

  private updateMapDelete(type: MyOrdersListType, orderId: string) {
    this.maps[type].delete(orderId);
    delete this.reactiveData[type].value[orderId];

    if (type === MyOrdersListType.filled) {
      this.orderLifecycleLogDelete(orderId);
    }
  }

  private resetActive() {
    this.snapshotReceived[MyOrdersListType.open] = false;
    this.updateMapReset(MyOrdersListType.open);
  }

  private resetInactive() {
    this.snapshotReceived[MyOrdersListType.filled] = false;
    this.updateMapReset(MyOrdersListType.filled);
  }

  private resetAuxiliaryData() {
    this.cancellingOrdersDelete();
    this.updateCacheDelete();
    this.orderLifecycleLogDelete();
  }

  private resetPerpsPositions() {
    this.setPerpsPositions({});
  }

  public reset() {
    this.resetActive();
    this.resetInactive();
    this.resetAuxiliaryData();
    this.resetPerpsPositions();
  }

  private orderLifecycleLogFill(event: WSSTradingUpdate | BEOrderSnapshotModel) {
    const ordersLifecycleLog = this.ordersLifecycleLog.get(event.order_id);
    const logData = {
      sequence: event.sequence,
      time: event.time,
      type: event.type,
    };

    if (ordersLifecycleLog) {
      ordersLifecycleLog.push(logData);
    } else {
      this.ordersLifecycleLog.set(event.order_id, [logData]);
    }
  }

  private orderLifecycleLogDelete(orderId?: string) {
    if (orderId) {
      this.ordersLifecycleLog.delete(orderId);
    } else {
      this.ordersLifecycleLog.clear();
    }
  }

  private async updateCacheReplay(orderId: string): Promise<void> {
    const events = this.updateCache.get(orderId);

    if (events) {
      await events.reduce(async (promise, event) => {
        await promise;
        return this.processWSSTradingUpdate(event);
      }, Promise.resolve());
    }
  }

  private updateCacheFill(update: WSSTradingUpdate) {
    const updateCache = this.updateCache.get(update.order_id);

    if (updateCache) {
      updateCache.push(update);
    } else {
      this.updateCache.set(update.order_id, [update]);
    }
  }

  private updateCacheDelete(orderId?: string) {
    if (orderId) {
      this.updateCache.delete(orderId);
    } else {
      this.updateCache.clear();
    }
  }

  private setOrders(orders: Array<OrderWithTradesModel>, type: MyOrdersListType) {
    orders.forEach((o) => {
      this.updateMapSet(type, o.id, o);
    });
  }

  public setSnapshot({ type, event: { orders } }: { type: OrderSnapshotType; event: { orders: Array<OrderTradesPair> } }) {
    switch (type) {
      case OrderSnapshotType.ACTIVE:
        this.resetActive();
        this.cancellingOrdersDelete();
        this.orderLifecycleLogDelete();
        break;
      case OrderSnapshotType.INACTIVE:
        this.resetInactive();
        break;
      default:
        break;
    }

    // because backend was sending ludicrous amounts of orders
    if (type === OrderSnapshotType.INACTIVE) {
      // eslint-disable-next-line no-param-reassign
      orders = orders.slice(0, CONSTANTS.INACTIVE_ORDER_LIST_LENGTH);
    }

    const no = orders.map((o) => {
      this.orderLifecycleLogFill(o.order);

      return new OrderWithTradesModel(o, this.updateReactiveDataKey.bind(this));
    });
    const tabsType = {
      [OrderSnapshotType.ACTIVE]: MyOrdersListType.open,
      [OrderSnapshotType.INACTIVE]: MyOrdersListType.filled,
    };
    const listType = tabsType[type];

    this.setOrders(no, listType);

    this.snapshotReceived[listType] = true;
  }

  private async normalizePosition(position: PerpsPosition | PerpsPositionUpdate | PerpsSettlement, existingPosition?: NormalizedPerpsPosition): Promise<NormalizedPerpsPosition> {
    const openVolume = Number(position.open_volume);
    const openPosition = Number('open_position' in position ? position.open_position : existingPosition?.open_position || '0');
    const averageEntryPrice = openVolume !== 0 ? (openPosition / openVolume).toString() : existingPosition?.average_entry_price || '0';

    logger.log('position', position);

    // For trade updates (PerpsPositionUpdate)
    if ('maintenance_margin' in position && !('mark_price' in position)) {
      logger.log('TRADE UPDATE', {
        ...existingPosition!,
        instrument_code: position.instrument_code,
        direction: position.direction,
        open_position: openPosition.toString(),
        open_volume: position.open_volume,
        maintenance_margin: position.maintenance_margin,
        average_entry_price: averageEntryPrice,
        last_update_time: new Date().toISOString(),
      });
      return {
        ...existingPosition!,
        instrument_code: position.instrument_code,
        direction: position.direction,
        open_position: openPosition.toString(),
        open_volume: position.open_volume,
        maintenance_margin: position.maintenance_margin,
        average_entry_price: averageEntryPrice,
        last_update_time: new Date().toISOString(),
      };
    }

    // For settlements (PerpsSettlement)
    if ('profit' in position) {
      return {
        ...existingPosition!,
        direction: position.direction,
        open_position: openPosition.toString(),
        open_volume: position.open_volume,
        margin_used: position.margin_used,
        mark_price: position.mark_price,
        last_settlement: position.profit,
        realised_pnl: position.total_profit,
        average_entry_price: averageEntryPrice,
        maintenance_margin: position.maintenance_margin,
        last_update_time: position.time,
        account_ratio: position.account_ratio,
        quote_currency_balance: position.quote_currency_balance || '0',
      };
    }

    // For initial snapshot (PerpsPosition)
    return {
      instrument_code: position.instrument_code,
      direction: position.direction,
      open_position: openPosition.toString(),
      open_volume: position.open_volume,
      margin_used: '0',
      mark_price: position.mark_price,
      last_settlement: position.last_settlement,
      realised_pnl: position.realised_pnl,
      average_entry_price: averageEntryPrice,
      maintenance_margin: 'maintenance_margin' in position ? position.maintenance_margin : '0',
      last_update_time: position.last_trade_timestamp || new Date().toISOString(),
      account_ratio: position.account_ratio,
      quote_currency_balance: position.quote_currency_balance || '0',
    };
  }

  public async updatePerpsPosition(instrumentCode: string, position: PerpsPositionUpdate | PerpsSettlement) {
    const positions = { ...this.reactivePerpsData.value };

    if (position.open_volume === '0') {
      delete positions[instrumentCode];
    } else {
      const existingPosition = this.reactivePerpsData.value[instrumentCode];
      positions[instrumentCode] = await this.normalizePosition(position, existingPosition);
    }

    this.setPerpsPositions(positions);
  }

  public async setPerpsPositionsSnapshot({ event: { positions } }: { event: ActivePositionsSnapshot }) {
    const perpsData: Record<string, NormalizedPerpsPosition> = {};
    // Process positions sequentially to maintain order
    await Promise.all(
      positions.map(async (position) => {
        perpsData[position.instrument_code] = await this.normalizePosition(position);
      }),
    );
    this.setPerpsPositions(perpsData);
  }

  private setPerpsPositions(positions: Record<string, NormalizedPerpsPosition>) {
    this.reactivePerpsData.value = positions;

    accountRatioService.processPositionsUpdate(Object.values(this.reactivePerpsData.value));
  }

  public processTradingUpdateAndAdjustBalance = async (event: WSSTradingUpdate) => {
    this.processWSSTradingBalanceUpdate(event);
    await this.processWSSTradingUpdate(event);
  };

  public processWSSTradingBalanceUpdate = (event: WSSTradingUpdate) => {
    switch (event.type) {
      case TradingUpdateType.ORDER_CREATED:
      case TradingUpdateType.ORDER_CLOSED:
      case TradingUpdateType.TRADE_SETTLED:
      case TradingUpdateType.ORDER_REJECTED: {
        const { balances, sequence } = event as OrderRejectedUpdate;

        balanceService.updateBalance({ update: balances, sequence });
        break;
      }
      case TradingUpdateType.STOP_ORDER_TRIGGERED: {
        break;
      }
      case TradingUpdateType.ORDER_FULLY_FILLED: {
        break;
      }
      default: {
        break;
      }
    }
  };

  public async processWSSTradingUpdate(event: WSSTradingUpdate): Promise<void> {
    const orderId = event.order_id;

    // Add the event to the queue
    if (!this.orderUpdateQueue.has(orderId)) {
      this.orderUpdateQueue.set(orderId, []);
    }
    this.orderUpdateQueue.get(orderId)!.push(event);

    this.processOrderQueue(orderId);
  }

  private processOrderQueue = (orderId: string) => {
    const events = this.orderUpdateQueue.get(orderId) || [];
    this.orderUpdateQueue.delete(orderId);

    // Sort events by sequence and time_nano
    events.sort((a, b) => {
      if (a.sequence === b.sequence) {
        return Number(a.time_nano || 0) - Number(b.time_nano || 0);
      }
      return Number(a.sequence) - Number(b.sequence);
    });

    // Process events in order
    events.forEach((event) => {
      this.processSingleUpdate(event);
    });
  };

  private async processSingleUpdate(event: WSSTradingUpdate): Promise<void> {
    const orderId = event.order_id;
    const order = this.getOrderById(orderId, MyOrdersListType.open) || this.getOrderById(orderId, MyOrdersListType.filled);

    this.orderLifecycleLogFill(event);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const processOrderData = async <T extends WSSTradingUpdate>(e: T, orderExistsCb: (e: T, order: OrderWithTradesModel, ...args: any[]) => void, ...args: any[]) => {
      /* legacy */
      if (order) {
        orderExistsCb(e, order, ...args);
      } else {
        this.updateCacheFill(event);
      }
    };

    switch (event.type) {
      case TradingUpdateType.OPEN: {
        await this.onOrderCreated(event as OrderBookedUpdate, true);
        break;
      }
      case TradingUpdateType.ORDER_CREATED: {
        await this.onOrderCreated(event as OrderCreatedUpdate);
        break;
      }
      case TradingUpdateType.ORDER_REJECTED: {
        processOrderData(event as OrderRejectedUpdate, this.onOrderRejected);
        break;
      }
      case TradingUpdateType.TRADE_SETTLED: {
        processOrderData(event as TradeSettledUpdate, this.onTradeSettled);
        break;
      }
      case TradingUpdateType.ORDER_CLOSED: {
        processOrderData(event as OrderClosedUpdate, this.onOrderClosed);
        break;
      }
      case TradingUpdateType.STOP_ORDER_TRIGGERED: {
        processOrderData(event as StopOrderTriggeredUpdate, this.onStopOrderTriggered);
        break;
      }
      case TradingUpdateType.ORDER_FULLY_FILLED: {
        processOrderData(event as OrderFullyFilledUpdate, this.onOrderFullyFilled);
        break;
      }
      // @ts-ignore TODO fix types
      case TradingUpdateType.SETTLEMENT: {
        // Update perps position
        // @ts-ignore TODO fix types
        this.updatePerpsPosition(event.instrument_code, event);
        break;
      }
      default: {
        logger.error(`${this.logPrefix} Unknown Trading update: ${JSON.stringify(event)}`);
      }
    }
  }

  private cancellingOrdersFill(orderId: string) {
    this.cancellingOrders.add(orderId);
  }

  private cancellingOrdersDelete(orderId?: string) {
    if (orderId) {
      this.cancellingOrders.delete(orderId);
    } else {
      this.cancellingOrders.clear();
    }
  }

  public cancelOrder = async (orderId: string) => {
    const order = this.getOrderById(orderId, MyOrdersListType.open);

    const logStuff = (message?: string) => {
      logger.error(
        `${this.logPrefix} order ${orderId}`,
        message,
        '\norder lifecycle:',
        JSON.stringify(this.ordersLifecycleLog.get(orderId)),
        '\norder update cache:',
        JSON.stringify(this.updateCache.get(orderId)),
      );
      this.orderLifecycleLogDelete(orderId);
    };

    if (!order) {
      logStuff('Order is missing');

      return Promise.resolve();
    }

    const nt = order.addOptimisticCancelledUpdate();

    this.updateReactiveDataKey(orderId, nt);

    if (this.cancellingOrders.has(orderId)) {
      return Promise.resolve();
    }

    this.cancellingOrdersFill(orderId);

    const cancelWithRest = async () => {
      try {
        return await PublicRest.Account.Orders.delete(orderId.toString());
      } catch (e) {
        // Resubscribe to the Account History in case of the compromised state
        if ((e as unknown as { status: number }).status === 404) {
          await accountService.subscribeToAccountHistory(authService.getRefreshedToken);
        }

        throw e;
      }
    };

    try {
      return await cancelWithRest();
    } catch (e) {
      logStuff((e as unknown as { message?: string }).message);

      throw e;
    }
  };

  public cancelAllOrders = () => {
    const cancelWithRest = async () => PublicRest.Account.Orders.deleteAll();

    try {
      return cancelWithRest();
    } catch (e) {
      logger.error(`${this.logPrefix} Cancel all orders failed`, e);

      throw e;
    }
  };

  private getOrderNotifications = () => {
    const { orderNotifications } = settingsService.settings.userSettings;

    return orderNotifications;
  };

  private onOrderCreated = async (event: OrderCreatedUpdate | OrderBookedUpdate, fake = false) => {
    const { order } = event;
    const createdOrder = {
      ...order,
      ...(fake ? event : {}),
      status: OrderStatus.OPEN,
      sequence: event.sequence,
    };

    this.setOrders(
      [
        new OrderWithTradesModel(
          {
            // @ts-ignore fix once all is stable
            order: createdOrder,
            trades: [],
          },
          this.updateReactiveDataKey.bind(this),
        ),
      ],
      MyOrdersListType.open,
    );

    const orderNotifications = this.getOrderNotifications();

    if (isMobileOnly && orderNotifications.all && orderNotifications.created) {
      orderToaster.showOrderCreatedToast(createdOrder.order_id, event);
    }

    await this.updateCacheReplay(createdOrder.order_id);
  };

  private onOrderRejected = (event: OrderRejectedUpdate, order: OrderWithTradesModel) => {
    const { order_id: orderId, reason, sequence, time, time_nano: timeNano, filled_amount: filledAmount } = event;

    const nt = order.addRejectedUpdate(sequence, reason, time, timeNano, filledAmount);

    this.decideOnAnimation(order);
    this.updateReactiveDataKey(orderId, nt);
    const orderNotifications = this.getOrderNotifications();

    if (orderNotifications.all && orderNotifications.rejected && order.fastTimeNanoCheck(timeNano)) {
      orderToaster.showOrderRejectedToast(event.order_id, event);
    }
  };

  private onTradeSettled = (event: TradeSettledUpdate, order: OrderWithTradesModel) => {
    const { trade, fee, sequence, time, time_nano: timeNano, order: orderDetails, position } = event;
    const { filled_amount: filledAmount } = orderDetails;

    const nt = order.addTradeSettledUpdate(new TradeModel({ trade, fee }), sequence, time, timeNano, filledAmount);

    this.decideOnAnimation(order);
    this.updateReactiveDataKey(order.id, nt);

    // Handle perp market position update
    if (position && trade.instrument_code.endsWith('_P')) {
      this.updatePerpsPosition(trade.instrument_code, position);
    }
  };

  private onOrderClosed = (event: OrderClosedUpdate, order: OrderWithTradesModel) => {
    const { sequence, time, time_nano: timeNano, order_id: orderId, filled_amount: filledAmount } = event;

    const nt = order.addCancelledUpdate(sequence, time, timeNano, filledAmount);

    this.updateReactiveDataKey(orderId, nt);
    this.cancellingOrdersDelete(orderId);
    const orderNotifications = this.getOrderNotifications();

    if (orderNotifications.all && orderNotifications.canceled && order.fastTimeNanoCheck(timeNano)) {
      orderToaster.showOrderClosedToast(event.order_id, event);
    }
  };

  private onStopOrderTriggered = (event: StopOrderTriggeredUpdate, order: OrderWithTradesModel) => {
    const { sequence, time, time_nano: timeNano, order_id: orderId } = event;

    const nt = order.addTriggeredUpdate(sequence, time, timeNano);

    this.decideOnAnimation(order);
    this.updateReactiveDataKey(orderId, nt);
    const orderNotifications = this.getOrderNotifications();

    if (orderNotifications.all && orderNotifications.triggered && order.fastTimeNanoCheck(timeNano)) {
      orderToaster.showOrderTriggeredToast(event.order_id, event);
    }
  };

  private onOrderFullyFilled = (event: OrderFullyFilledUpdate, order: OrderWithTradesModel) => {
    const { sequence, time, time_nano: timeNano } = event;

    order.addFullyFilledUpdate(sequence, time, timeNano, event.order.filled_amount);

    this.decideOnAnimation(order);

    // Show the order as filled in the open orders list
    this.updateReactiveDataKey(order.id, { before: [MyOrdersListType.open], now: [MyOrdersListType.open] });

    // Schedule moving the order to the filled list after a delay
    setTimeout(() => {
      this.updateReactiveDataKey(order.id, { before: [MyOrdersListType.open], now: [MyOrdersListType.filled] });
    }, 30000); // 30 seconds delay

    orderToaster.showOrderFilledToast(order);
  };

  private decideOnAnimation(order: OrderWithTradesModel) {
    if (!settingsService.settings.userSettings?.myOrdersSettings.allowAnimations) {
      return;
    }

    switch (order.status) {
      case OrderStatus.FILLED_FULLY:
      case OrderStatus.STOP_TRIGGERED:
        order.animationUpdateWithCache(OrderAnimation.success, order.status);
        break;
      case OrderStatus.REJECTED:
      case OrderStatus.FAILED:
      case OrderStatus.FILLED_REJECTED:
        order.animationUpdateWithCache(OrderAnimation.fail, order.status);
        break;
      default:
        break;
    }
  }

  public fetchOrderHistory(
    payload: OrderHistoryFilter,
    { maxPageSize, unprocessedHistory }: { maxPageSize: number; unprocessedHistory: true },
  ): Promise<{
    cursor: string | undefined;
    orderHistory: Array<OrderTradesPair>;
  }>;

  public fetchOrderHistory(
    payload: OrderHistoryFilter,
    { maxPageSize, unprocessedHistory }: { maxPageSize: number; unprocessedHistory: false },
  ): Promise<{
    cursor: string | undefined;
    orderHistory: Array<OrderWithTradesModel>;
  }>;

  public async fetchOrderHistory(
    payload: OrderHistoryFilter,
    { maxPageSize = 20, unprocessedHistory = false }: { maxPageSize: number; unprocessedHistory: boolean },
  ): Promise<{
    cursor: string | undefined;
    orderHistory: Array<OrderTradesPair> | Array<OrderWithTradesModel>;
  }> {
    const MAX_PAGE_SIZE = maxPageSize.toString();
    const withJustFullyFilledMap = {
      [OrderHistoryFilterCategory.ACTIVE]: undefined,
      [OrderHistoryFilterCategory.INACTIVE]: true,
    };

    const getQueryParams = () => ({
      from: payload.from,
      to: payload.to,
      with_just_filled_inactive: withJustFullyFilledMap[payload.category],
      max_page_size: MAX_PAGE_SIZE,
      with_cancelled_and_rejected: payload.withCancelledAndRejected,
      ...(payload.instrumentCode && { instrument_code: payload.instrumentCode }),
      ...(payload.cursor && { cursor: payload.cursor }),
    });

    const oh = await PublicRest.Account.Orders.getAll(getQueryParams());

    const orderHistory = unprocessedHistory ? oh.order_history : oh.order_history.map((r) => new OrderWithTradesModel({ order: r.order, trades: r.trades }, () => {}));

    return {
      orderHistory,
      cursor: oh.cursor,
    };
  }

  public setOrderSnapshotFromRest = async (category: 'inactive' | 'active') => {
    let orders: Array<OrderTradesPair> = [];

    try {
      ({ orderHistory: orders } = await this.fetchOrderHistory(
        {
          category,
          withCancelledAndRejected: category === 'inactive',
        },
        { maxPageSize: 80, unprocessedHistory: true },
      ));

      this.setSnapshot({ event: { orders }, type: category === 'inactive' ? OrderSnapshotType.INACTIVE : OrderSnapshotType.ACTIVE });
    } catch (e) {
      logger.error(`${this.logPrefix} fetching ${category} orders failed`, e);
    }
  };
}

const os = new OrderService();

export default os;
