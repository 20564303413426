export enum OrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP = 'STOP',
}

export enum OrderSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum OrderSnapshotType {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum MyOrdersListType {
  open = 'open',
  filled = 'filled',
  balances = 'balances',
  positions = 'positions',
}

export enum PerpsOrdersListType {
  positions = 'positions',
  orders = 'orders',
  history = 'history',
}

export enum MyOrdersTabs {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  HIDDEN = 'HIDDEN',
}

export enum OrderStatus {
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
  FAILED = 'FAILED',
  FILLED = 'FILLED',
  FILLED_CLOSED = 'FILLED_CLOSED',
  FILLED_FULLY = 'FILLED_FULLY',
  FILLED_REJECTED = 'FILLED_REJECTED',
  OPEN = 'OPEN',
  REJECTED = 'REJECTED',
  SLIPPAGE_PREVENTED = 'SLIPPAGE_PREVENTED',
  STOP_TRIGGERED = 'STOP_TRIGGERED',
}

export enum OrderStatusFast {
  CANCELLED = 'CANCELLED',
  FILL = 'FILL',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INSUFFICIENT_LIQUIDITY = 'INSUFFICIENT_LIQUIDITY',
  MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT = 'MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT',
  MATCHING_POST_ONLY_RESULTS_IN_MATCH = 'MATCHING_POST_ONLY_RESULTS_IN_MATCH',
  MOVED = 'MOVED',
  MOVE_SELF_TRADE = 'MOVE_SELF_TRADE',
  RISK_FAILED_OVER_MAX_POSITION = 'RISK_FAILED_OVER_MAX_POSITION',
  RISK_INVALID_RESERVE_BID_PRICE = 'RISK_INVALID_RESERVE_BID_PRICE',
  RISK_MARGIN_TRADING_DISABLED = 'RISK_MARGIN_TRADING_DISABLED',
  SELF_TRADE = 'SELF_TRADE',
  USER_MGMT_USER_IN_DERISK = 'USER_MGMT_USER_IN_DERISK',
}

export const OrderStatusesRejected = [
  OrderStatus.FILLED_REJECTED,
  OrderStatus.REJECTED,
  OrderStatusFast.INSUFFICIENT_FUNDS,
  OrderStatusFast.INSUFFICIENT_LIQUIDITY,
  OrderStatusFast.MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT,
  OrderStatusFast.MATCHING_POST_ONLY_RESULTS_IN_MATCH,
  OrderStatusFast.MOVE_SELF_TRADE,
  OrderStatusFast.RISK_FAILED_OVER_MAX_POSITION,
  OrderStatusFast.RISK_INVALID_RESERVE_BID_PRICE,
  OrderStatusFast.RISK_MARGIN_TRADING_DISABLED,
  OrderStatusFast.SELF_TRADE,
  OrderStatusFast.USER_MGMT_USER_IN_DERISK,
];

export type OrderStatusRejected = (typeof OrderStatusesRejected)[number];

export const OrderStatusFastToLegacy = {
  // Legacy statuses
  [OrderStatus.OPEN]: 'OPEN',
  [OrderStatus.STOP_TRIGGERED]: 'STOP_TRIGGERED',
  [OrderStatus.FILLED]: 'FILLED',
  [OrderStatus.FILLED_CLOSED]: 'FILLED_CLOSED',
  [OrderStatus.FILLED_FULLY]: 'FILLED_FULLY',
  [OrderStatus.CLOSING]: 'CLOSING',
  [OrderStatus.CLOSED]: 'CLOSED',
  [OrderStatus.REJECTED]: 'REJECTED',
  [OrderStatus.FILLED_REJECTED]: 'FILLED_REJECTED',
  [OrderStatus.FAILED]: 'FAILED',

  // Fast statuses
  [OrderStatusFast.CANCELLED]: 'CLOSED',
  [OrderStatusFast.FILL]: 'FILLED',
  [OrderStatusFast.INSUFFICIENT_FUNDS]: 'REJECTED',
  [OrderStatusFast.INSUFFICIENT_LIQUIDITY]: 'REJECTED',
  [OrderStatusFast.MOVED]: 'OPEN',
  [OrderStatusFast.SELF_TRADE]: 'REJECTED',

  // New Fast rejected statuses added as part of Perps
  [OrderStatusFast.MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT]: 'OPEN',
  [OrderStatusFast.MATCHING_POST_ONLY_RESULTS_IN_MATCH]: 'REJECTED',
  [OrderStatusFast.MOVE_SELF_TRADE]: 'OPEN',
  [OrderStatusFast.RISK_FAILED_OVER_MAX_POSITION]: 'REJECTED',
  [OrderStatusFast.RISK_INVALID_RESERVE_BID_PRICE]: 'REJECTED',
  [OrderStatusFast.RISK_MARGIN_TRADING_DISABLED]: 'REJECTED',
  [OrderStatusFast.USER_MGMT_USER_IN_DERISK]: 'REJECTED',
};

export const finalStatuses = {
  common: [OrderStatus.CLOSED, OrderStatus.FAILED, OrderStatus.FILLED_CLOSED, OrderStatus.FILLED_FULLY, OrderStatus.FILLED_REJECTED, OrderStatus.REJECTED],
  get [OrderStatus.FILLED]() {
    return this.common;
  },
  get [OrderStatus.STOP_TRIGGERED]() {
    return this.common;
  },
};

export enum OrderAnimation {
  fail = 'fail',
  success = 'success',
  none = '',
}

export enum TimeInForceType {
  FILL_OR_KILL = 'FILL_OR_KILL',
  GOOD_TILL_CANCELLED = 'GOOD_TILL_CANCELLED',
  GOOD_TILL_TIME = 'GOOD_TILL_TIME',
  IMMEDIATE_OR_CANCELLED = 'IMMEDIATE_OR_CANCELLED',
  POST_ONLY = 'POST_ONLY',
}

/* eslint-disable camelcase */
export interface BEOrderModel {
  account_holder: string;
  account_id: string;
  amount: string;
  expire_after?: Date;
  filled_amount: string;
  instrument_code: string;
  is_post_only?: boolean;
  order_id: string;
  price?: string;
  sequence: number;
  side: OrderSide;
  time_in_force?: TimeInForceType;
  time_last_updated?: string; // only for filled orders
  time_triggered?: string;
  time: string;
  trigger_price?: string;
  type: OrderType;
}

export interface BEOrderSnapshotModel extends BEOrderModel {
  status: OrderStatus;
  average_price?: string; // only if it has trades
  update_modification_sequence?: number; // only if it has trades
  total_fee?: number; // calculated with average price
  fee_currency?: string;
  time_nano?: number;
}
/* eslint-enable camelcase */

export interface InMyOrderType {
  before: Array<MyOrdersListType> | undefined;
  now: Array<MyOrdersListType> | undefined;
}
