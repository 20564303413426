import rest from '@exchange/libs/utils/rest/src';

const URL = '/beneficiary-data';

export type PaymentsBeneficiaryDataHostedKnownVASP = {
  beneficiaryVASPdid: string;
  beneficiaryVASPname: string;
};

export type PaymentsBeneficiaryDataHostedUnknownVASP = {
  beneficiaryVASPname: string;
};

export type PaymentsBeneficiaryDataUnhosted = {
  beneficiaryProof: {
    proof: 'checked';
    type: 'checkbox_confirmation';
  };
};

export type PaymentsBeneficiaryData = PaymentsBeneficiaryDataHostedKnownVASP | PaymentsBeneficiaryDataHostedUnknownVASP | PaymentsBeneficiaryDataUnhosted;

export default function PaymentsTransactionResource({ base }) {
  return {
    async setBeneficiaryData(transactionId: string, payload: PaymentsBeneficiaryData): Promise<void> {
      await rest.request({
        method: 'post',
        payload,
        url: `/${transactionId}${URL}`,
        base,
      });
    },
  };
}
